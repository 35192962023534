import React, { Component } from 'react';
// import { useStaticQuery, graphql } from 'gatsby'
import PubSub from 'pubsub-js'
import Excerpt from './Excerpt'
import SummaryDetail from './ui/SummaryDetail';
// import Img from 'gatsby-image'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
          contact: null,
        }
    }

    componentDidMount(){
        const { contact } = this.props.options
        this.setState({
            contact: contact
        })
    }

    _click = (e) => {
        console.log("click", e.target)
        PubSub.publish("ABOUT_SHOW_CONTENT")
    }
    render(){
        const { options, about } = this.props
        const { contact } = this.state
        // console.log(options.mentions_credits.childMarkdownRemark.html)
        return (            
            <footer>
                {/* <pre>{JSON.stringify(options)}</pre> */}
                <div className="xs-only">
                    <div className="about-toggle " onClick={this._click}>
                        <Excerpt excerpt={about.excerpt} />
                    </div>
                </div>
                <div className="contact">
                    {contact &&
                        <div className="texte"
                        dangerouslySetInnerHTML={{
                            __html: contact.childMarkdownRemark.html,
                        }}></div>
                    }
                </div>
                <div className="logotype">
                    <figure>
                        <img  
                            src={options.logotype.file.url} 
                            alt="logotype" />
                        <figcaption>{options.baseline_footer}</figcaption>
                    </figure>

                </div>
                <div className="mentions">
                    <SummaryDetail 
                    summary="Mentions légales et crédits" 
                    detail={options.mentions_credits.childMarkdownRemark.html} /> 
                </div>
            </footer>
        );
    }
}

export default Footer;