/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react'
import Helmet from 'react-helmet'
// import { withPrefix } from "gatsby"
import { Layout } from './src/components/Layout'

// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// const _wrapRootElement = ({ element }) => {
//     return (
//         <>
//             <Helmet>
//             {/* <script src="https://cdn.jsdelivr.net/mojs/latest/mo.min.js"></script> */}
//                 <script src={withPrefix('mo.min.js')} type="text/javascript" />
//             </Helmet>
//             {element}
//         </>
//     );
// }
const _wrapRootElement = ({ element }) => {
    // console.log(element)
    return (
        <>
            <Helmet>
                <script src="https://unpkg.com/pace-js@1.0.2/pace.min.js"></script>
                {/* <script type="text/javascript" src={withPrefix('splitting.min.js')} /> */}
            </Helmet>
            {element}
        </>
    );
}


const _wrapPageElement = ({ element, props }) => {
    // console.log(props)
    // if(props.pageContext.template === "404"){
    //     return element
    // }else{
    //     return <Layout 
    //     {...props}>{element}</Layout>
    // }
    return <Layout 
        {...props}>{element}</Layout>
}

export { _wrapRootElement, _wrapPageElement }