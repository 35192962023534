import React, { Component } from 'react';
// import { useStaticQuery, graphql } from 'gatsby'
import PubSub from 'pubsub-js'
import { ContextHoc } from '../context/ContextHoc'
import Img from 'gatsby-image'
import TexteLexique from './ui/TexteLexique'
import Excerpt from './Excerpt'


class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      isExcerpt: false,
      isContent: false,
      // excerptHtml: ''
    }
    //this._wheeling = this._wheeling.bind(this)
    // this._onResize = this._onResize.bind(this)
    // this._outsideClickListener = this._outsideClickListener.bind(this)
  }

  componentWillUnmount(){
    // window.removeEventListener("resize", this._onResize)
    window.removeEventListener("scroll", this._onScroll)
    document.removeEventListener('click', this._outsideClickListener)
    PubSub.unsubscribe(this.tokenToggle);
    PubSub.unsubscribe(this.tokenClose);
    PubSub.unsubscribe(this.tokenToggleContent);
    PubSub.unsubscribe(this.tokenRouteUpdate);
  }

  componentDidMount(){
    // this._splitExcerpt()

    // this.minY = window.innerHeight/2

    // console.log(excerptHtml)
    this.tokenClose = PubSub.subscribe('ABOUT_CLOSE', (e) => {
      this._close()
    })

    this.tokenToggle = PubSub.subscribe('ABOUT_TOGGLE', (e) => {
      // console.log(e)
      this._toggle()
    })

    // this.tokenToggle = PubSub.subscribe('ABOUT_OPEN', () => {
    //   this._toggle()
    // })

    this.tokenToggleContent = PubSub.subscribe('ABOUT_SHOW_CONTENT', () => {
      this._showContent()
    })
    

    this.tokenRouteUpdate = PubSub.subscribe('ROUTE_UPDATE', (e,d) => {
      this.isHome = d.location.pathname === "/"
      this.setState({
        // active: this.isHome
        isExcerpt: this.isHome
      })
    })

    // window.addEventListener("resize", this._onResize)
    
    // setTimeout(() => {
    //   this._onResize()
    // }, 150)

    window.addEventListener("scroll", this._onScroll)
    document.addEventListener('click', this._outsideClickListener)
  }

  _onScroll = (e) => {
    // const {isContent, pageYOffset} = this.state
    // if(isContent)
    //   window.scrollTo(0, pageYOffset);
    // else{
    //   this.setState({
    //     pageYOffset: window.pageYOffset
    //   })
    // }
    this.setState({
      pageYOffset: window.pageYOffset
    })
  }

  _onResize = () =>{
    // const isAffix = document.body.classList.contains("affix")
    // console.log("isAffix",isAffix)
    // const availableHeight = window.innerHeight - 40
    // let aboutHeight = availableHeight
    // if(isAffix){
    //   aboutHeight = window.innerHeight - 127
    // }
    // const { height } = this.refs.about.getBoundingClientRect()
    // document.querySelector('.excerpt').style.height = height+"px"
    // document.querySelector('.excerpt').style.color = "blue"
    // this.refs.about.style.height = aboutHeight+"px"
    // this.refs.about.style.height = availableHeight+"px"
// const bodyHeight = window.innerHeight
// document.documentElement.style.height = bodyHeight+"px"
// document.body.style.height = bodyHeight+"px"
// console.log(bodyHeight)
    // const wrapper = document.querySelector('#about .content');
    // const wrapperHeight = wrapper.getBoundingClientRect().height
    // this.refs.aboutToggle.style.height = wrapperHeight+"px"

    // const links = document.querySelectorAll('.lexique-word');
    // links.forEach(link => {
    //   const isLeft = _getIsLeftIsRight(link)
    //   const className = isLeft ? "is-left" : "is-right"
    //   // console.log(className)
    //   link.className = "lexique-word "+className
    //   // link.classList.add(className)
    // })

  }

  _outsideClickListener = (event) => {
    if('ontouchstart' in window)return

    const {isContent} = this.state
    if(!isContent)return

    const target = event.target;
// console.log(target)
    if( !target.closest("#about") ){
      this._toggle()
    }

  }

  _toggle = () => {
    console.log("_toggle")
    this.setState({
      isContent: !this.state.isContent,
      isExcerpt: !this.state.isExcerpt
    }, () => {
      if(this.state.isContent){
        this._showContent()
      }else{
        this._hideContent()
        
      }
    })
  }

  _close = () => {
    this.setState({
      isContent: false,
      isExcerpt: true
    }, () => {
      if(this.state.isContent){
        this._showContent()
      }else{
        this._hideContent()
        
      }
    })
  }

  _showContent = () => {
    console.log("_showContent")
    // const scrollY = window.scrollY 
    // document.body.style.position = 'fixed';
    // document.body.style.top = `-${scrollY}px`;
    // return
    document.body.classList.add("is-about")

    document.addEventListener('click', this._outsideClickListener)
    this._onResize();
    // this.refs.about.classList.add("content-reveal")
    this.setState({
      isContent: true
    })
  }

  _hideContent = () => {
    // const scrollY = document.body.style.top;
    // console.log(parseInt(scrollY))
    // document.body.style.position = '';
    // document.body.style.top = '';
    // window.scrollTo(0, parseInt(scrollY || '0') * -1);

    document.body.classList.remove("is-about")
    PubSub.publish("ABOUT_IS_CLOSED")
    document.removeEventListener('click', this._outsideClickListener)
    this.refs.content.scrollTo(0, 0)
    document.querySelectorAll(".tool-tip").forEach(el => {
      el.classList.remove("active")
    })
  }

  render(){
    const {isContent, pageYOffset} = this.state
    // const {data} = this.props
    const { about } = this.props.context
    // console.log(about.excerpt.childMarkdownRemark.rawMarkdownBody)
    const className = this.isHome 
    ? (isContent ? "is-content": "is-excerpt")
    : (isContent ? "is-content": "")
    return(
   

      <div id="about" ref="about" className={className}>
        
        <div className="inner">
          <div className="sticky">
            
            <div className="about-content" >
              <Excerpt excerpt={about.excerpt} pageYOffset={pageYOffset} />
              
              <div className="content" ref="content">
                <div className="header r-h">
                  Notre approche
                </div>
                <section className="approche">
                  <TexteLexique texte={about.approche.childMarkdownRemark.html} />
                </section>
                {about.images &&
                  <section className="images">
                    <div className="row">
                    {about.images.map((image, i) => (
                      <div className={"col-xs-12 "+(image.sizes.aspectRatio < 1 ? "col-md-6" : "")} key={i}>
                        <Img 
                        // className={}
                        key={i} 
                        fluid={image.fluid} 
                        alt={image.description}
                        Tag="figure" />
                        </div>
                    ))}
                    </div>
                  </section>
                }
              </div>
            </div>

            <div 
              className="about-toggle"
              ref="aboutToggle"
              onClick={this._toggle}
              ><div className="plus"></div>
            </div>

          </div>
        </div>
      </div>
 
    )
  }
}

export default ContextHoc(About);

