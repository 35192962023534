export function fileNameByUrl(url){
    const decoded = decodeURIComponent(url)
    return decoded.substring(decoded.lastIndexOf('/') + 1)
}

export function _getIsLeftIsRight(el){
    // console.log(el)
    // console.log(wrapper)
    // const parent = wrapper.getBoundingClientRect();
    // const parentMid = parent.width/2
    const windowMid = window.innerWidth/2
    const child = el.getBoundingClientRect();
    const childX = child.x
// console.log(parentMid, childX)
    const isLeft = childX < windowMid
// console.log(childX, windowMid)
    return isLeft;
}


export function _throttle(callback, interval) {
    let enableCall = true;
  
    return function(...args) {
      if (!enableCall) return;
  
      enableCall = false;
      callback.apply(this, args);
      setTimeout(() => enableCall = true, interval);
    }
  }