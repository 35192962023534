import React from "react"
// import Helmet from "react-helmet";
import { useStaticQuery, graphql } from 'gatsby'
import Transition from "./Transition";
import Header from "./Header"
import Footer from "./Footer"
import About from "./About"

//import WrapperContext from "../context/WrapperContext"
const WrapperContext = React.createContext()



//const LocaleContext = React.createContext()

// const Layout = ({ children, location, pageContext: { template } }) => {
const Layout = ({ children, pageContext: { template } }) => {
    const { 
      options, 
      about,
      allLexique
    } = useStaticQuery(query)
    
    const key = Math.floor(Math.random() * 100)
    // console.log(template)
    return(
      <WrapperContext.Provider value={{ options, about, allLexique }}>
        <div id="page">
                
          <Header options={options} isHome={template === "home"} />
          <main>
            
            <div className="page-content">
              <Transition _key={key}>
                {children}
              </Transition>
            </div>
              
              <About data={about} />
            
          </main>
          <Footer options={options} about={about} />  
            
        </div>
      </WrapperContext.Provider>
  
    )
}

export { WrapperContext, Layout }

const query = graphql`
  query Options {
    options: contentfulOptions{
      ...options
    }    
    about:contentfulAPropos {
      ...about
    } 
    allLexique: allContentfulLexique {
      edges {
        node {
          ...lexique
        }
      }
    }
  }
`