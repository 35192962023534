import React, { Component } from 'react';
import PubSub from 'pubsub-js'
// import TexteSplit from './ui/TexteSplit';

class Excerpt extends Component {
    constructor(props) {
        super(props)
        this.state = {
            excerptHtml: '',
            counter: 0,
            index: 1
        }
    }

    componentDidMount(){
        const isTouch = window.innerWidth <= 1023
        // if('ontouchstart' in window) {
        if(isTouch) {
            this.setState({
                excerptHtml: this.props.excerpt.childMarkdownRemark.rawMarkdownBody
            })

            return
        }
        this._splitExcerpt()
    }

    _splitExcerpt = () => {
        // console.log(this.props.excerpt.childMarkdownRemark.rawMarkdownBody)
        // const reg = /[\\.!\?]/
        // const reg = /\b((?!=|\,|\.).)+(.)\b/g
        const reg = /\S.*?\."?(?=\s|$)/g
        // const excerptParts = this.props.excerpt.childMarkdownRemark.rawMarkdownBody.split(" ")
        // const excerptParts = this.props.excerpt.childMarkdownRemark.rawMarkdownBody.split(reg)
        const excerptParts = this.props.excerpt.childMarkdownRemark.rawMarkdownBody.match(reg)
        // console.log(excerptParts)
        let excerptHtml = ''
        excerptParts.forEach(el => {
            excerptHtml += '<span>' + el + '</span> ';
        })
        // console.log(excerptParts)
        this.setState({
            excerptHtml: excerptHtml,
            excerptLength: excerptParts.length
        }, () => {
            this._update()
        })
    }

    _showContent = () => {
        PubSub.publish("ABOUT_SHOW_CONTENT")
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        // console.log(prevProps)
        if('ontouchstart' in window) {
            this._showAll()
            return
        }
        if (this.props.pageYOffset === prevProps.pageYOffset) return
        // console.log(this.props.pageYOffset, prevProps.pageYOffset)

        // console.log(Math.round(window.pageYOffset / 30))
        const windowYOffset = Math.round(window.pageYOffset / 200)

        this.setState({
            pageYOffset: this.props.pageYOffset,
            index: windowYOffset > 1 ? windowYOffset : 1
 
        }, () => {
            this._update()
        })
    }
    
    _update = () => {
        const {excerptLength, index} = this.state
        // const step = Math.round(this.props.pageYOffset/(excerptLength * 2))
        // console.log(index, excerptLength)
        if(index <= excerptLength){
            this.refs.excerpt.querySelectorAll("span").forEach((el, i) => { 
                // console.log(" - "+i,index)
                const spanIndex = i+1
                if(spanIndex <= index){
                    this.refs.excerpt.querySelector("span:nth-child(n+"+spanIndex+")").style.opacity = 1
                }else{
                    this.refs.excerpt.querySelector("span:nth-child(n+"+spanIndex+")").style.opacity = 0
                }
            })

        }
        if(index > excerptLength){
            this.refs.excerpt.querySelector("button").style.opacity = 1
        }else{
            this.refs.excerpt.querySelector("button").style.opacity = 0
        }
    }

    _showAll(){
        this.refs.excerpt.querySelectorAll("span").forEach((el, i) => { 
            el.style.opacity = 1
        })
        this.refs.excerpt.querySelector("button").style.opacity = 1
    }

    render() {
        const {excerptHtml} = this.state
        // const texte = this.props.excerpt.childMarkdownRemark.rawMarkdownBody

        return (
            <div className="excerpt" ref="excerpt" onClick={this._showContent}>
                <div className="sticky">
                    <div className="texte strong" 
                    dangerouslySetInnerHTML={{
                    __html: excerptHtml,
                    }}
                    ></div>
                    {/* <TexteSplit texte={texte} /> */}
                    <button >En savoir plus sur notre approche –></button>
                </div>
            </div>
        );
    }
}

export default Excerpt;