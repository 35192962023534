import React, { Component } from 'react';
import PubSub from 'pubsub-js'
import { Link } from 'gatsby';
import { ContextHoc } from '../context/ContextHoc'

class Header extends Component {
    // constructor(props) {
    //     super(props)
    //     // this._handleScroll = this._handleScroll.bind(this)
    // }

    componentWillUnmount(){
        window.removeEventListener("scroll", this._handleScroll)
        PubSub.unsubscribe(this.about)
    }

    componentDidMount(){
        this._consoleCredits()
        // if('ontouchstart' in window)return
        
        this.header = document.querySelector("header")
        this.about = document.querySelector("#about")
        this.main = document.querySelector("main")

        this.about= PubSub.subscribe('ABOUT_IS_CLOSED', (e) => {
            this._update()
        })

        window.addEventListener("scroll", this._handleScroll)
        this._update()
        setTimeout(() => {
            this._update()
        }, 150)
    }

    _consoleCredits(){
        var e = new Date(),
            r = function(t) {
                    return t < 10 ? "0".concat(t) : t
                }
        var n = "".concat(e.getFullYear(), "_").concat(r(e.getMonth() + 1), "_").concat(r(e.getDate()), " ").concat(r(e.getHours()), ":").concat(r(e.getMinutes()));
        console.log("%c \n%ca_e_a_i_%c\nv".concat("1.0.0", " – ").concat(n, "\nahmedghazi.com\n%c "), "line-height: 100px", "font-size: 25px; line-height: 1em; font-family: sans-serif, system-ui; font-weight: bold;", "font-size: 10px;  line-height: 1em; font-weight: bold;", "color: red;line-height: 100px")
    }

    _handleScroll = () => {
        if (!this.ticking) {
            this.ticking = true
            this.requestID = requestAnimationFrame(() => this._update())
        }
    }
    
    _update() {
        // const {bottom, height} = this.header.getBoundingClientRect()
        const {top} = this.main.getBoundingClientRect()
        const isAbout = document.body.classList.contains("is-about")
        // console.log(top)
        // if(top > 40){
        //     this.about.style.top = top+"px"
        // }
// console.log(top)
        if(top < 0){
            document.body.classList.add("affix")      
        }else{
            if(!isAbout)
                document.body.classList.remove("affix")
        }    

        this.ticking = false

    }

    _closeAbout = () => {
        if('ontouchstart' in window)PubSub.publish("ABOUT_CLOSE")
    }

    render() {
        //const { options } = this.props
        const { isHome } = this.props
        const { options } = this.props.context
        //console.log(options)
        return (
            <header>
                <Link to="/" onClick={this._closeAbout}>
                    <div className="inner">
                        {isHome 
                        ? <h1 className="inner"><span className="site-name">{options.title}</span> <span className="baseline">{options.baseline}</span></h1>
                        : <><div className="site-name">{options.title}</div><div className="baseline">{options.baseline}</div></>
                        }
                        
                        
                    </div>
                </Link>
            </header>
        );
    }
}

export default ContextHoc(Header);

