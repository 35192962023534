import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition
} from "react-transition-group";

const timeout = 250;
// const getTransitionStyles = {
//   entering: {
//     // position: `absolute`,
//     opacity: 0
//   },
//   entered: {
//     transition: `opacity ${timeout}ms ease-in-out`,
//     opacity: 1
//   },
//   exiting: {
//     transition: `opacity ${timeout}ms ease-in-out`,
//     opacity: 0
//   }
// };

class Transition extends React.PureComponent {
  render() {
    const { children, _key } = this.props;
    // const main = document.querySelector("main")
    // console.log(main)
    // console.log(location)
    return (
      <TransitionGroup>
        <ReactTransition
          key={_key}
          timeout={{
            enter: timeout,
            // exit: timeout
            exit: timeout
          }}
        >
            {status => {
                // console.log(status)
                // if(main)main.className = "is-"+status
                return(
                    <div className={"is-"+status}
                    // style={{
                    //     ...getTransitionStyles[status]
                    // }}
                    >
                    {children}
                    </div>
          )}}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

export default Transition;
