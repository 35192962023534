import React, { Component } from 'react';
import { ContextHoc } from '../../context/ContextHoc'
import {_throttle} from '../../core/utils'
import {_getIsLeftIsRight} from '../../core/utils'
// import { renderToString } from 'react-dom/server'
// import TexteLexiqueWord from './TexteLexiqueWord'
// import mojs from 'mo-js';

class TexteLexique extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //texte: ""
            // active: false
        }
        // this._shootLines = this._shootLines.bind(this)
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this._onResize)
        window.removeEventListener("scroll", this._handleScroll)
        document.querySelector("#about .content").removeEventListener("scroll", this._handleScroll)
    }

    componentDidMount(){
        window.addEventListener("resize", this._onResize)
        window.addEventListener("scroll", this._handleScroll)
        document.querySelector("#about .content").addEventListener("scroll", this._handleScroll)

        // const isTouch = 'ontouchstart' in window
        const isTouch = window.innerWidth <= 1023 && 'ontouchstart' in window
// console.log("isTouch",isTouch)
        const {allLexique} = this.props.context
        const len = allLexique.edges.length

        allLexique.edges.forEach((el, i) => {
            this._lexify(el.node)
            // console.log(el.node)
            if(i === len-1){
                const links = this.refs.text.querySelectorAll('.lexique-word');
                links.forEach(link => {
                    // const word = link.querySelector(".word")
                    
                    if(!isTouch){
                        link.addEventListener('mouseover', this._open)
                        link.addEventListener('mouseout', _throttle(this._close, 150))
                        document.querySelectorAll('.lexique-word--definition').forEach(el => {
                            el.addEventListener('mouseleave', this._closeAll)
                        })
                    }
                    if(isTouch){
                        // console.log(link)
                        link.addEventListener('click', this._open)
                        // link.addEventListener('touch', this._open)
                        document.querySelectorAll('.lexique-word--header').forEach(el => {
                            el.addEventListener('click', this._closeAll)
                        })
                        
                    }
                    
                });
                this._onResize()
            }
        })
    }

    _lexify(node){
        // console.log(node.terme)
        const texte = this.refs.text.innerHTML
        const reg = new RegExp( '' + node.terme + 's?', 'gi');
        const matches = texte.match(reg);
        // console.log(matches)
        if(matches && matches.length > 0){
            const replace = `<span class="lexique-word" data-id="${node.id}">` +
                `<span class="word" >${matches[0]}</span>` +
                `<span class="lexique-word--cta"></span>` +
                
            `</span>`;
            const output = texte.replace(reg, replace);
            this.refs.text.innerHTML = output
            
            const toolTipWrapper = document.createElement("div");
            toolTipWrapper.setAttribute("class", "tool-tip");

            const toolTip = 
            `<div class="lexique-word--definition" data-id=${node.id}>` +
                `<div class="lexique-word--header">` +
                    `<div class="lexique-word--label">${node.terme}</div>` +
                    `<div class="lexique-word--close xs-only"></div>` +
                `</div>`+
                `<div class="lexique-word--content ">` +
                    `${node.definition.childMarkdownRemark.rawMarkdownBody}` +
                `</div>` +
                `${node.illustration 
                    ? `<img src="${node.illustration.file.url}" />` 
                    : ''}` +
            `</div>`;
        
            document.body.append(toolTipWrapper)
            toolTipWrapper.innerHTML = toolTip
            
        }

    }

    _open = (e) => {
        console.log(e)
        this._closeAll()
        
        const id = e.target.dataset.id
        const toolTip = document.querySelector('.lexique-word--definition[data-id="'+id+'"]').parentNode
        if(!toolTip)return
        this.word = e.target
        this.toolTip = toolTip
        // e.target.classList.add("active")

        this._positionTooltip()
        this.toolTip.classList.add("active")
        // this._()
        // console.log(toolTip)
        // 
    }

    _close = (e) => {
        const id = e.target.dataset.id
        const toolTip = document.querySelector('.lexique-word--definition[data-id="'+id+'"]').parentNode
        if(!toolTip)return

        
        // e.target.classList.remove("active")
        toolTip.classList.remove("active")
    }

    // _format = () => {

    // }

    _toggle = (e) => {
        this._closeAll()

        const id = e.target.dataset.id
        const toolTip = document.querySelector('.lexique-word--definition[data-id="'+id+'"]').parentNode
        if(!toolTip)return
console.log(toolTip)
        toolTip.classList.toggle("active")

    }

    _closeAll = (e) => {
        // console.log("_closeAll")
        document.querySelectorAll(".tool-tip").forEach(el => {
            el.classList.remove("active")
        })
        this.word = null
        this.toolTip = null
    }

    _positionTooltip = () => {
        // console.log(this.toolTip)
        if(this.toolTip){
            const {left, top, right, height, width} = this.word.getBoundingClientRect()
            const topPlusLineHeight = top + height + 15
            const isLeft = this.word.classList.contains("is-left")
            // console.log(top, height)
            if(isLeft){
                this.toolTip.style.left = (left + width - 15)+"px"
                this.toolTip.classList.add("is-left")
            } else {
                const posRight = window.innerWidth - right + 15
                this.toolTip.style.right = posRight+"px"
                this.toolTip.classList.add("is-right")
            }
            this.toolTip.style.top = topPlusLineHeight+"px"
            
        }
    }

    _handleScroll = () => {
        this._positionTooltip()
    }

    _onResize = () => {
        const links = document.querySelectorAll('.lexique-word');
        links.forEach(link => {
          const isLeft = _getIsLeftIsRight(link)
          const className = isLeft ? "is-left" : "is-right"
          // console.log(className)
          link.className = "lexique-word "+className
          // link.classList.add(className)
        })
    }
    
    render() {
        // console.log(this.state.texte)
        const { texte } = this.props
        // const { active } = this.state
        // const className = active ? "active" : ""
        return (
            <div className="texte" ref="text"
            dangerouslySetInnerHTML={{
                __html: texte,
            }}
            ></div>
        );
    }
}

export default ContextHoc(TexteLexique);