import "./src/styles/index.scss"

//import React from 'react'
import PubSub from 'pubsub-js';
import {
    _wrapRootElement,
    _wrapPageElement
} from './wrapPageElement'
// import {
//     withPrefix
// } from "gatsby"

export const wrapRootElement = _wrapRootElement
export const wrapPageElement = _wrapPageElement



// const injectScript = (file) => {
//     var head = document.getElementsByTagName("head")[0];
//     var script = document.createElement('script');
//     script.src = file;
//     script.type = 'text/javascript';

//     head.appendChild(script);
// }

// let injectedScript = false

export const onClientEntry = () => {
    // if (!injectedScript) {
    //     injectScript(withPrefix('mo.min.js'))
    //     injectedScript = true
    // }
}

export const onRouteUpdate = ({ location }) => {
    //console.log('new pathname', location.pathname)
    PubSub.publish('ROUTE_UPDATE', {location: location})
}